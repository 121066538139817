import { BaseModel } from "src/shared/types/base-model.interface";

export interface AdvanceModel extends BaseModel {
  auto_ach_enabled: boolean;
  collection_method: "ach";
  createdAt: string;
  current_balance: number;
  estimated_term_months: number | null;
  factor: number;
  min_payment: number | null;
  purchase_price: number;
  split_percentage: number;
  status: AdvanceStatus;
  title: string;
  total_payback: number;
  reference_number: string;
  funded_date: string;
  updatedAt: string;
}

export enum AdvanceStatus {
  DRAFT = "draft",
  SUBMITTED_TO_MERCHANT = "submitted to merchant",
  AWAITING_SIGNATURES = "awaiting signatures",
  FUNDED_IN_REPAYMENT = "funded - in repayment",
  PAID_IN_FULL = "paid in full",
  IN_COLLECTIONS = "in collections",
  DECLINED = "declined",
}
