import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@core/store/hooks";
import {
  selectCurrentUser,
  selectLoading,
} from "@core/store/app/app-selectors";
import {
  pushNotification,
  setLoading,
  setUser,
} from "@core/store/app/app-slice";
import { AuthService } from "@core/services/auth.service";
import { NotificationPromptTypes } from "src/shared/types/notification.type";
import { AuthRoutes } from "src/auth/auth.router";
import { UserRoles } from "@core/models/user.model";
import { AdminRoutes } from "src/admin/admin.router";

const MERCHANT_NAVIGATION = [
  //   {
  //     name: "Dashboard",
  //     to: `${AdminRoutes.MODULE}/`,
  //     icon: HomeIcon,
  //     current: true,
  //   },
  //   {
  //     legend: "Payments:",
  //     delimiter: true,
  //   },
  {
    name: "Advances",
    to: `${AdminRoutes.MODULE}/${AdminRoutes.ADVANCES}/`,
    icon: CurrencyDollarIcon,
    current: true,
    legend: null,
    delimiter: false,
  },
];

const ADMIN_NAVIGATION = [
  {
    name: "Dashboard",
    to: `${AdminRoutes.MODULE}/`,
    icon: HomeIcon,
    current: true,
  },
  {
    legend: "Access Management",
    delimiter: true,
  },
  {
    name: "Users",
    to: `users/`,
    icon: UserIcon,
    current: true,
  },
];

const userNavigation = [{ key: "profile", name: "Profile", href: "/profile" }];

export const AppShellComponent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectLoading);
  const user = useAppSelector(selectCurrentUser);

  const navigation =
    user?.role.type === UserRoles.ROOT_ADMIN
      ? ADMIN_NAVIGATION
      : MERCHANT_NAVIGATION;

  const UserMenuContextButton = () => {
    return (
      <>
        {loading ? (
          <div
            role="status"
            className="max-w-sm animate-pulse dark:border-gray-700"
          >
            <div className="flex items-center space-x-4">
              <svg
                className="w-8 h-8 text-gray-200 dark:text-gray-700"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <div className="hidden lg:block">
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-12 mb-2" />
                <div className="w-20 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
              </div>
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <Menu.Button className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            {/* <img
                                className="h-8 w-8 rounded-full bg-gray-50"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                            /> */}
            <span className="hidden lg:flex lg:items-center">
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                aria-hidden="true"
              >
                {user?.username}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
        )}
      </>
    );
  };

  const logout = async () => {
    dispatch(setLoading(true));

    try {
      await AuthService.logout();
    } catch (e) {
      dispatch(
        pushNotification({
          type: NotificationPromptTypes.ERROR,
          message: "Failed to logout. We're already looking at this problem.",
        })
      );
    } finally {
      dispatch(setUser(null));
      navigate(AuthRoutes.MODULE);
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <div className="h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center text-white text-lg font-bold border-b border-white">
                      <NavLink
                        to={AdminRoutes.MODULE}
                        onClick={() => setSidebarOpen(false)}
                      >
                        red360
                      </NavLink>
                    </div>

                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item, index) =>
                              item.delimiter ? (
                                <li
                                  className="relative"
                                  key={`${index}_divider_mobile`}
                                >
                                  <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true"
                                  >
                                    <div className="w-full border-t border-gray-800" />
                                  </div>
                                  <div className="relative flex justify-start">
                                    <span className="bg-black px-2 text-sm text-gray-500">
                                      {item.legend}
                                    </span>
                                  </div>
                                </li>
                              ) : (
                                <li key={item.name}>
                                  <NavLink
                                    end={true}
                                    to={item.to as string}
                                    onClick={() => setSidebarOpen(false)}
                                    className={({ isActive }) => {
                                      return classNames(
                                        isActive
                                          ? "bg-gray-800 text-white"
                                          : "text-white hover:bg-gray-800",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      );
                                    }}
                                  >
                                    {/* @ts-ignore */}
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-white"
                                          : "group-hover:text-white",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </NavLink>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-20 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center text-white text-lg font-bold">
              <NavLink to={`${AdminRoutes.MODULE}/`}>red360</NavLink>
            </div>

            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item, index) =>
                      item.delimiter ? (
                        <li
                          className="relative"
                          key={`${index}_divider_desktop`}
                        >
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-800" />
                          </div>
                          <div className="relative flex justify-start">
                            <span className="bg-black px-2 text-sm text-gray-500">
                              {item.legend}
                            </span>
                          </div>
                        </li>
                      ) : (
                        <li key={item.name}>
                          <NavLink
                            end={true}
                            to={item.to as string}
                            className={({ isActive }) => {
                              return classNames(
                                isActive
                                  ? "bg-gray-800 text-white"
                                  : "text-white hover:bg-gray-800",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              );
                            }}
                          >
                            {/* @ts-ignore */}
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-white"
                                  : "group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </NavLink>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72 h-full">
          <div className="sticky top-0 z-30 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                />
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                <Menu as="div" className="relative">
                  <UserMenuContextButton />
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.key}>
                          {() => (
                            <NavLink
                              to={item.href}
                              className={({ isActive }) => {
                                return classNames(
                                  isActive ? "bg-gray-50" : "",
                                  "block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100"
                                );
                              }}
                            >
                              {item.name}
                            </NavLink>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item key="logout">
                        {() => (
                          <li
                            onClick={logout}
                            className="block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer hover:bg-gray-100"
                          >
                            Logout
                          </li>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};
