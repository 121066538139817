import cn from "classnames";
import type { TableSkeletonInterface } from "./table-skeleton.interface";

export function TableSkeleton({
  cols,
  rows,
}: TableSkeletonInterface) {
  const Col = ({
    index,
  }: {
    index: number;
  }) => {
    const FirstCol = () => {
      return (
          <div className="pl-4 pr-3 py-3.5 flex-1">
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
      );
    };
    const MiddleCol = () => {
      return (
          <div className="px-3 py-3.5 flex-1">
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
      );
    };
    const LastCol = () => {
      return (
          <div className="px-3 py-3.5 flex-1">
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12 " />
          </div>
      );
    };

    const Item = () => {
      if (index === 0) {
        return <FirstCol />;
      } if (index === cols - 1) {
        return <LastCol />;
      }
      return <MiddleCol />;
    };
    return (
        <Item />
    );
  };

  const Row = ({
    index,
  }: {
    index: number;
  }) => {
    return (
        <div className={
          cn(index === 0 ? "bg-gray-50" : "", "flex items-center justify-between")
        }
        >
            {
                  Array.from({ length: cols }).map((_, ndx) => (
                      <Col index={ndx} key={`COL-${index}-${ndx}`} />
                  ))
                }
        </div>
    );
  };

  return (
      <div
          role="status"
          className="overflow-hidden w-full animate-pulse dark:divide-gray-700 dark:border-gray-700"
      >
          <Row index={0} key="skeleton-header" />
          <div className="common-page-table-body-height">
              {
            Array.from({ length: rows }).map((_, index) => (
                <Row index={index + 1} key={index} />
            ))
          }
          </div>
          <span className="sr-only">Loading...</span>
      </div>
  );
}
