import axios from "axios";

export const API_HOST =
  process.env.REACT_APP_API_URL || "http://localhost:1337";

export const ApiClient = axios.create({
  baseURL: `${API_HOST}/api`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    "Content-Type": "application/json",
  },
});

ApiClient.interceptors.request.use(async (request: any) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    request.headers!["Authorization"] = `Bearer ${token}`;
  }

  return request;
});
