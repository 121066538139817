import type { RouteObject } from "react-router";
import { LoginPage } from "./pages/login.page";
import { AuthWrapper } from "./components/auth-wrapper.component";

export enum AuthRoutes {
  MODULE = "/auth",
}

export const AuthRouter: RouteObject[] = [
  {
    path: AuthRoutes.MODULE,
    element: <AuthWrapper />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
];
