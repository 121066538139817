import type { RouteObject } from "react-router";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { AuthRouter } from "src/auth/auth.router";
import { AuthorizedLayer } from "./shared/components/authorized-layer.component";
import { AppShellComponent } from "./shared/components/app.shell.component";
import { AdminRouter } from "src/admin/admin.router";
import { ProfileRouter } from "src/profile/profile.router";
// import { HomeRouter } from "src/home/home.router";

const routes: RouteObject[] = [
  ...AuthRouter,
  // ...HomeRouter,
  {
    element: <AuthorizedLayer component={<AppShellComponent />} />,
    children: [...AdminRouter, ...ProfileRouter],
  },
  {
    path: "*",
    element: <Navigate to="/auth" replace />,
  },
];

const Router = createBrowserRouter(routes);

export default Router;
