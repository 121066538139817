import cn from "classnames";

interface TableHeadingInterface {
  title: string;
  subtitle?: string;
  controls?: any;
  containerClassName?: string;
}

export function PageHeading({
  title,
  subtitle,
  controls,
  containerClassName,
}: TableHeadingInterface) {
  return (
      <div
          className={
          cn(
            containerClassName ?? "",
            "px-6 flex justify-between items-center border-b border-gray-200 pb-6 pt-6",
          )
          }
      >
          <div className="sm:flex-auto">
              <h1 className="text-lg font-semibold leading-7 text-gray-900">{title}</h1>
              {
                    subtitle
                      ? (<p className="mt-1 text-sm text-gray-700"> {subtitle} </p>)
                      : (<></>)
                }
          </div>
          <div>
              {controls ?? (<></>)}
          </div>
      </div>
  );
}
