import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  EyeIcon as OpenedEye,
  EyeSlashIcon as ClosedEye,
} from "@heroicons/react/24/outline";
import { type SubmitHandler, useForm } from "react-hook-form";
import classNames from "classnames";

import { AuthService } from "@core/services/auth.service";
import { useAppDispatch, useAppSelector } from "@core/store/hooks";
import {
  pushNotification,
  setLoading,
  setUser,
} from "@core/store/app/app-slice";
import { selectCurrentUser } from "@core/store/app/app-selectors";
import { NotificationPromptTypes } from "src/shared/types/notification.type";
import { FormField, FormFieldLabel } from "src/shared/components/form";
import { UsersService } from "@core/services/users.service";
import { UserModel, UserRoles } from "@core/models/user.model";
import { AdminRoutes } from "src/admin/admin.router";
import { Logo } from "src/shared/components/logo";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function LoginPage() {
  let user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const { register, trigger, control, handleSubmit, watch } = useForm<any>();

  const [hidePassword, setHidePassword] = useState(true);
  const [allowSubmit, setAllowSubmit] = useState(false);

  useEffect(() => {
    const tryAuthorizeUser = async () => {
      const user = await UsersService.getMe();
      dispatch(setUser(user as UserModel));
    };

    if (!user) {
      // check if user token is stored locally
      tryAuthorizeUser();
    } else {
      navigate(`${AdminRoutes.ADVANCES}/`);
    }
  }, [user]);

  useEffect(() => {
    const [identifier, password] = [watch("identifier"), watch("password")];

    if (identifier?.length && password?.length) {
      setAllowSubmit(true);
    }
  }, [watch("identifier"), watch("password")]);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<any> = async (data) => {
    dispatch(setLoading(true));

    try {
      const attempt = await AuthService.login(data);

      localStorage.setItem("access_token", attempt.jwt);

      const user = await UsersService.getMe();
      dispatch(setUser(user));

      switch (user.role?.type) {
        case UserRoles.CUSTOMER:
          navigate(`${AdminRoutes.MODULE}/`);
          break;
        default:
          navigate(`${AdminRoutes.MODULE}/`);
          break;
      }
    } catch (e: any) {
      if (
        e?.response?.status === 400 ||
        e?.response?.status === 401 ||
        e?.response?.status === 403
      ) {
        dispatch(
          pushNotification({
            type: NotificationPromptTypes.ERROR,
            message: "Invalid login. Please try again.",
          })
        );
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          <Logo />
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            required={true}
            control={control}
            fieldPath="identifier"
            id="email"
            type="email"
            autoComplete="email"
            placeholder="myuser@example.com"
            registerReturn={register("identifier", {
              pattern: {
                value: emailRegex,
                message: "Invalid email address",
              },
              minLength: {
                value: 5,
                message: "You should type min 5 symbols",
              },
              required: "Email is required field.",
              onChange: () => trigger(),
            })}
            CustomFormFieldLabel={() => (
              <div className="flex items-center justify-between">
                <FormFieldLabel
                  required={true}
                  control={control}
                  fieldPath="identifier"
                  htmlFor="email"
                  label="Email address"
                />

                {/* <div className="text-sm">
                  <NavLink
                    to={`${AuthRoutes.MODULE}`}
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    Forgot password?
                  </NavLink>
                </div> */}
              </div>
            )}
          />

          <FormField
            required={true}
            control={control}
            fieldPath="password"
            label="Password"
            id="password"
            type={hidePassword ? "password" : "text"}
            autoComplete="current-password"
            fieldSuffixIconContainerClasses="right-7 pr-3"
            placeholder={hidePassword ? "*************" : "qwerty12345"}
            InputSuffixComponent={() => (
              <>
                {hidePassword ? (
                  <ClosedEye
                    onClick={() => setHidePassword(!hidePassword)}
                    className="h-6 w-6 cursor-pointer shrink-0 text-gray-500 group-hover:text-white absolute right-3 top-1.5"
                    aria-hidden="true"
                  />
                ) : (
                  <OpenedEye
                    onClick={() => setHidePassword(!hidePassword)}
                    className="h-6 w-6 cursor-pointer shrink-0 text-gray-500 group-hover:text-white absolute right-3 top-1.5"
                    aria-hidden="true"
                  />
                )}
              </>
            )}
            registerReturn={register("password", {
              minLength: {
                value: 6,
                message: "You should type min 6 symbols",
              },
              required: "Password is required field.",
              onChange: () => trigger(),
            })}
          />

          <button
            type="submit"
            disabled={!allowSubmit}
            className={classNames(
              allowSubmit
                ? "bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600 text-white"
                : "bg-gray-200 hover:bg-gray-200 focus-visible:outline-gray-200 disabled cursor-not-allowed text-gray-700",
              "flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mt-2"
            )}
          >
            Sign in
          </button>
        </form>
      </div>
    </>
  );
}
