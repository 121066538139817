import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import { AdvanceModel } from "@core/models/advance.model";
// import { formatAmount } from "src/shared/helpers/currency-utils";

interface AdvanceSelectorProps {
  advances: AdvanceModel[];
  selectedAdvance: AdvanceModel | null;
  onAdvanceSelect: (advance: AdvanceModel) => void;
}

export const AdvanceSelector: React.FC<AdvanceSelectorProps> = ({
  advances,
  selectedAdvance,
  onAdvanceSelect,
}) => {
  const sortedAdvances = [...advances].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {selectedAdvance ? (
            <div className="flex gap-1 items-start">
              <p>#{selectedAdvance.reference_number}</p>•
              <p>
                {new Date(selectedAdvance.funded_date).toLocaleDateString()}
              </p>
              •<p>{selectedAdvance.status}</p>
              {/* •<p>{selectedAdvance.title}</p>• */}
              {/* <p>
                {formatAmount(
                  selectedAdvance.total_payback -
                    selectedAdvance.current_balance
                )}
              </p>
              •<p>{formatAmount(selectedAdvance.current_balance)}</p>•
              <p>{formatAmount(selectedAdvance.total_payback)}</p> */}
            </div>
          ) : (
            "Select Advance"
          )}
          <ChevronDownIcon
            aria-hidden="true"
            className="-mr-1 h-5 w-5 text-gray-400"
          />
        </MenuButton>
      </div>
      <MenuItems className="absolute left-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
        {sortedAdvances.map((advance) => (
          <MenuItem key={advance.id}>
            <button
              onClick={() => onAdvanceSelect(advance)}
              className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
            >
              {new Date(advance.createdAt).toLocaleDateString()} -{" "}
              {advance.status} - ${advance.purchase_price}
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};
