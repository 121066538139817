import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline";
import classnames from "classnames";
import { DOTS, usePagination } from "../../hooks/use-pagination.hook";

export interface Pagination {
  total: number;
  count: number;
  per_page: number;
  current_page: number;
  total_pages: number;
}

interface PaginationProps {
  pagination: Pagination;
  onPageSet: (page: number) => any;
}

export function PaginationComponent({ pagination, onPageSet }: PaginationProps) {
  const paginationRange = usePagination({
    currentPage: pagination?.current_page,
    totalCount: pagination?.total,
    siblingCount: 1,
    pageSize: pagination?.per_page,
  });

  if (!paginationRange) {
    return <PaginationPlaceholder />;
  }

  if (pagination.current_page === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageSet(pagination.current_page + 1);
  };

  const onPrevious = () => {
    onPageSet(pagination.current_page - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
      <nav
          className={classnames("flex items-center border-t border-gray-200 px-6")}
      >
          <div className="-mt-px flex w-0 mr-auto">
              <div
                  className={classnames("inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700", {
                    disabled: pagination.current_page === 1,
                  })}
                  onClick={onPrevious}
              >
                  <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />

              </div>
          </div>

          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return <div key={`${pageNumber}${index}`} className="pt-4">&#8230;</div>;
            }

            return (
                <button
                    key={`page-${pageNumber}`}
                    className={classnames("inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-500 hover:text-gray-800", {
                      "text-gray-700 border-gray-300": pageNumber === pagination.current_page,
                      "border-transparent": pageNumber !== pagination.current_page,
                    })}
                    onClick={() => onPageSet(pageNumber as number)}
                >
                    {pageNumber}
                </button>
            );
          })}
          <button
              className={classnames("ml-auto inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700", {
                disabled: pagination.current_page === lastPage,
              })}
              onClick={onNext}
          >
              <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
      </nav>
  );
}

const PaginationPlaceholder = () => (
    <nav className="flex items-center justify-between border-t border-gray-200 px-6">
        <div className="-mt-px flex w-0 flex-1">
            <a
                href="#"
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </a>
        </div>

        <div className="flex md:hidden">
            <button
                className="inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600"
                aria-current="page"
            >
                2
            </button>
        </div>

        <div className="hidden md:-mt-px md:flex">
            <a
                href="#"
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                1
            </a>
            {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
            <a
                href="#"
                className="inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600"
                aria-current="page"
            >
                2
            </a>
            <a
                href="#"
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                3
            </a>
            <span
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
            >
                ...
            </span>
            <a
                href="#"
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                8
            </a>
            <a
                href="#"
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                9
            </a>
            <a
                href="#"
                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                10
            </a>
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
            <button
        // onClick={() => onPageSet(pagination.current_page + 1)}
        // disabled={nextDisabled}
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                {/* Next */}
                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
        </div>
    </nav>
);
