import React, { type HTMLInputTypeAttribute } from "react";
import type { Control, UseFormRegisterReturn } from "react-hook-form";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { FormFieldLabel } from "./form-field-label.component";
import { FormFieldWrapper } from "./form-field-wrapper.component";
import { FormFieldErrorMessage } from "./form-field-error-message.component";

interface Props {
  id?: string;
  autoComplete?: string;
  control: Control<any, any>;
  fieldPath: string;
  type?: HTMLInputTypeAttribute;
  CustomControl?: React.FC | React.ComponentClass;
  label?: string;
  placeholder?: string;
  customStyleClasses?: string;
  fieldSuffixIconContainerClasses?: string;
  required?: boolean;
  registerReturn?: UseFormRegisterReturn<any>;
  renderControlWrapper?: boolean;
  CustomFormFieldLabel?: React.FC | React.ComponentClass;
  InputSuffixComponent?: React.FC | React.ComponentClass;
}

export const FormField: React.FC<Props> = (
  {
    control,
    id,
    autoComplete,
    registerReturn,
    placeholder,
    fieldPath,
    fieldSuffixIconContainerClasses,
    label,
    required,
    type,
    CustomControl,
    CustomFormFieldLabel,
    renderControlWrapper = true,
    InputSuffixComponent,
    customStyleClasses,
  },
) => {
  return (
      <div className={classNames(customStyleClasses)}>
          {
        CustomFormFieldLabel
          ? (<CustomFormFieldLabel />)
          : (
              <FormFieldLabel
                  required={required as boolean}
                  control={control}
                  fieldPath={fieldPath}
                  htmlFor={fieldPath}
                  label={label as string}
              />
          )
      }
          {
        renderControlWrapper ? (
            <FormFieldWrapper
                control={control}
                fieldPath={fieldPath}
                customStyleClasses="mt-2 relative"
            >
                {
              CustomControl
                ? <CustomControl />
                : (
                    <input
                        autoComplete={autoComplete}
                        type={type || "text"}
                        id={id || fieldPath}
                        placeholder={placeholder}
                        className={classNames(
                          "py-1.5 text-gray-900 sm:text-sm sm:leading-6 border-none w-full outline-none rounded-md",
                        )}
                        {...registerReturn}
                    />
                )
            }
                <FormFieldErrorMessage
                    control={control}
                    fieldPath={fieldPath}
                    CustomErrorComponent={() => (
                        <div className={classNames("pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3", fieldSuffixIconContainerClasses)}>
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                    )}
                />
                {InputSuffixComponent && <InputSuffixComponent />}
            </FormFieldWrapper>
        ) : (
          CustomControl
            ? <CustomControl />
            : (
                <input
                    type={type || "text"}
                    id={fieldPath}
                    placeholder={placeholder}
                    className={classNames(
                      "py-1.5 text-gray-900 sm:text-sm sm:leading-6 border-none w-full outline-none rounded-md",
                    )}
                    {...registerReturn}
                />
            )
        )
      }

          <FormFieldErrorMessage control={control} fieldPath={fieldPath} />
      </div>
  );
};
