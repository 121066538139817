import { RouterProvider } from "react-router-dom";
import "./App.scss";
import Router from "./routes";
import { ToastNotifications } from "src/shared/components/toast-notifications.component";

function App() {
  return (
    <>
      <ToastNotifications />
      <RouterProvider router={Router} />
    </>
  );
}

export default App;
