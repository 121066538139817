import type { RouteObject } from "react-router";
import { ProfilePage } from "./pages/profile.page";

export enum ProfileRoutes {
  MODULE = "/profile",
}

export const ProfileRouter: RouteObject[] = [
  {
    path: ProfileRoutes.MODULE,
    children: [
      {
        index: true,
        element: <ProfilePage />,
      },
    ],
  },
];
