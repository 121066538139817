import { HttpParams } from "./http-params";

export class APIQueryBuilder {
  public filters = new Map<string, unknown>();
  public sort?: string;
  public per_page = 20;
  public page = 1;
  private include?: string;
  private params!: HttpParams | null;

  public getClone(): APIQueryBuilder {
    const builder = new APIQueryBuilder();
    builder.setPerPage(this.per_page);
    builder.setPage(this.page);

    if (this.sort) {
      builder.setSort(this.sort);
    }
    this.filters.forEach((value, key) => {
      builder.addFilter(key, value);
    });

    return builder;
  }

  public mergeDataFromObject(data: Record<string, any>): APIQueryBuilder {
    Object.entries(data).forEach(([key, value]) => {
      if (key === "include") {
        this.setInclude(value);
      } else if (key === "page") {
        this.setPage(Number(value));
      } else if (key === "per_page") {
        this.setPerPage(Number(value));
      } else {
        this.addFilter(key, value);
      }
    });

    return this;
  }

  public mergeDataFromParams(params: HttpParams): APIQueryBuilder {
    params.keys().forEach((key) => {
      const value = params.get(key);
      if (!value) {
        return;
      }

      if (key === "per_page") {
        this.per_page = +value;
      } else if (key === "page") {
        this.page = +value;
      } else if (key === "sort") {
        this.setSort(value);
      } else {
        this.addFilter(key.slice(7, key.length - 1), value);
      }
    });

    return this;
  }

  public addFilter(name: string, value: unknown): APIQueryBuilder {
    this.filters.set(name, value);
    return this;
  }

  public addFilterIfTruthyValue(name: string, value: unknown): APIQueryBuilder {
    if (!value) {
      return this;
    }

    this.filters.set(name, value);
    return this;
  }

  public setParams(params: HttpParams): APIQueryBuilder {
    this.params = params;
    return this;
  }

  public getParams(): HttpParams | null {
    return this.params;
  }

  public removeFilter(name: string): APIQueryBuilder {
    this.filters.delete(name);
    return this;
  }

  public setInclude(value?: string): APIQueryBuilder {
    this.include = value;
    return this;
  }

  public setPerPage(perPage: number): APIQueryBuilder {
    this.per_page = perPage;
    return this;
  }

  public setSort(name: string, ascending = true): APIQueryBuilder {
    this.sort = `${ascending ? "" : "-"}${name}`;
    return this;
  }

  public removeSort(): APIQueryBuilder {
    this.sort = undefined;
    return this;
  }

  public setPage(page: number): APIQueryBuilder {
    this.page = page;
    return this;
  }

  public build(skip: string[] = []): HttpParams {
    let params = new HttpParams();

    /* Filters */
    // @ts-ignore
    // todo clarify
    for (const filter of this.filters.entries()) {
      if (filter[1] !== null && filter[1] !== undefined && filter[1] !== "") {
        params = params.set(`filter[${filter[0]}]`, String(filter[1]));
      }
    }

    /* Includes */
    if (this.include) {
      params = params.set("include", this.include);
    }

    /* Sorts */
    if (this.sort) {
      params = params.set("sort", this.sort);
    }

    /* Page */
    if (this.page) {
      params = params.set("page", this.page.toString());
    }

    /* Per Page */
    if (this.per_page && !skip.includes("per_page")) {
      params = params.set("per_page", this.per_page.toString());
    }

    return params;
  }
}
