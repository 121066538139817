import React, { type JSX } from "react";

import { get } from "lodash";
import { type Control, useFormState, type FieldPath } from "react-hook-form";

interface Props {
  control: Control<any, any>;
  fieldPath: string;
  CustomErrorComponent?: () => JSX.Element;
}

export const FormFieldErrorMessage: React.FC<Props> = ({ control, CustomErrorComponent, fieldPath }) => {
  const { errors, touchedFields } = useFormState<any>({ control, name: fieldPath as FieldPath<any> });

  const isTouched = get(touchedFields, fieldPath);
  if (!get(errors, fieldPath) || !!get(errors, fieldPath) && !isTouched) {
    if (CustomErrorComponent) {
      return null;
    }
    return <div className="mt-2 h-5" />;
  }

  if (CustomErrorComponent) {
    return <CustomErrorComponent />;
  }

  return (
      <p className="mt-2 text-sm text-red-600" id="title-error">
          {get(errors, fieldPath)?.message?.toString()}
      </p>
  );
};
