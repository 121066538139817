import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/core/store/hooks";
import { setLoading, setUser } from "src/core/store/app/app-slice";
import { UserModel } from "src/core/models/user.model";
import { UsersService } from "src/core/services/users.service";
import { AuthRoutes } from "src/auth/auth.router";

interface ProtectedRouteInterface {
  children: any;
}
export function ProtectedRouteComponent({ children }: ProtectedRouteInterface) {
  const user = useAppSelector((state) => state.app.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user === null) {
      tryAuthorizeUser();
    }
  }, [user, navigate]);

  const tryAuthorizeUser = async () => {
    dispatch(setLoading(true));
    try {
      const me: UserModel | null = await UsersService.getMe();
      dispatch(setUser(me as UserModel));
    } catch (e) {
      navigate(AuthRoutes.MODULE);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return children;
}
