import { ApiClient } from '@core/api/api-client';
import { UserModel } from '@core/models/user.model';

export interface AuthDTO {
  identifier: string;
  password: string;
}

export interface AuthResponse {
  jwt: string;
  user: UserModel;
}

export const AuthService = new class {
  public baseEndpoint = `/auth`;

  public async login(payload: AuthDTO): Promise<AuthResponse> {
    const response = await ApiClient.post(`${this.baseEndpoint}/local`, payload);

    return response.data;
  }

  public async logout(): Promise<void> {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }

}();
