import { Navigate, type RouteObject } from "react-router";
import { AdvancesPage } from "src/admin/pages/advances.page";
// import { DashboardPage } from "src/admin/pages/dashboard.page";

export enum AdminRoutes {
  MODULE = "/admin",
  ADVANCES = "advances",
}

export const AdminRouter: RouteObject[] = [
  {
    path: AdminRoutes.MODULE,
    children: [
      {
        path: AdminRoutes.ADVANCES,
        index: false,
        element: <AdvancesPage />,
      },
      {
        path: "",
        index: true,
        id: "dashboard",
        // element: <DashboardPage />,
        element: <Navigate to={AdminRoutes.ADVANCES} replace />,
      },
    ],
  },
];
