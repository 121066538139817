import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { NotificationPrompt } from 'src/shared/types/notification.type';
import type { UserModel } from "../../models/user.model";

interface AppState {
  user: UserModel | null;
  loading: boolean;
  toastAlerts: NotificationPrompt[];
}


const initialState = {
  user: null,
  loading: false,
  toastAlerts: [],
} as AppState;

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state: AppState, action: PayloadAction<UserModel | null>) => {
      state.user = action.payload;
    },
    setLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    pushNotification: (state: AppState, action) => {
      state.toastAlerts.push({
        id: Date.now(),
        message: action.payload.message,
        type: action.payload.type,
      });
    },
    deleteNotification: (state: AppState, action: PayloadAction<number>) => {
      state.toastAlerts.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const {
  setUser,
  setLoading,
  pushNotification,
  deleteNotification,
} = appSlice.actions;
