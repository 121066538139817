import type { BaseModelList } from "../types/base-model.interface";

export function parsePagination(data: BaseModelList<any>) {
  return {
    total: data.meta.pagination.total,
    count: data.meta.pagination.pageCount,
    per_page: data.meta.pagination.pageSize,
    current_page: data.meta.pagination.page,
    total_pages: data.meta.pagination.total / data.meta.pagination.pageSize,
  };
}
