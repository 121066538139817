import React from "react";

import { get } from "lodash";
import { type Control, useFormState, type FieldPath } from "react-hook-form";
import cn from "classnames";

interface Props {
  control: Control<any, any>;
  fieldPath: string;
  label?: string;
  htmlFor?: string;
  customStyleClasses?: string;
  required?: boolean;
}

export const FormFieldLabel: React.FC<Props> = ({ control, required, label, customStyleClasses, htmlFor, fieldPath }) => {
  const { touchedFields, errors } = useFormState<any>({ control, name: fieldPath as FieldPath<any> });

  const isTouched = get(touchedFields, fieldPath);
  const hasError = !!get(errors, fieldPath);

  return (
      <label
          htmlFor={htmlFor}
          className={
           cn(
             required ? "required" : "",
             hasError && isTouched && "text-red-600",
             customStyleClasses,
             "block text-sm font-medium leading-6 text-gray-900")
           }
      >
          {label}
      </label>
  );
};
