export enum NotificationPromptTypes {
  SUCCESS = "success",
  ERROR = "error",
}

export interface NotificationPrompt {
  id: number;
  message: string;
  type: NotificationPromptTypes;
}
