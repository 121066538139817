import { ApiClient } from "src/core/api/api-client";
import { PaymentModel } from "@core/models/payment.model";
import { BaseModelList } from "src/shared/types/base-model.interface";

export const PaymentsService = new (class {
  public baseEndpoint = `/payments`;

  public async getPayments(
    advanceId: number,
    page: number
  ): Promise<BaseModelList<PaymentModel>> {
    const response = await ApiClient.get(
      `${this.baseEndpoint}?populate=payment_type,advance&filters[advance][id][$eq]=${advanceId}&pagination[page]=${page}&pagination[pageSize]=20`
    );
    return response.data;
  }
})();
