import { BaseModel } from 'src/shared/types/base-model.interface';
import { BusinessModel } from '@core/models/business.model';

export interface UserModel extends BaseModel {
  username: string;
  email: string;
  provider: "local";
  confirmed: boolean;
  blocked: boolean;
  role: UserRoleModel;
  businesses: BusinessModel[];
}

export interface UserRoleModel extends BaseModel {
  description: string;
  name: string;
  type: UserRoles;
}

export enum UserRoles {
  CUSTOMER = "Customer",
  ROOT_ADMIN = "root_admin",
}
