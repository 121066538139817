import { ProtectedRouteComponent } from "./protected-route.component";

interface AuthorizedLayerInterface {
  component: any;
}

export const AuthorizedLayer = ({
  component,
}: AuthorizedLayerInterface) => (
    <ProtectedRouteComponent>
        {component}
    </ProtectedRouteComponent>
);
