// import { Menu } from "@headlessui/react";
// import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
// import { FunnelIcon } from "@heroicons/react/20/solid";
import { PaymentModel } from "@core/models/payment.model";
import {
  Pagination,
  PaginationComponent,
} from "src/shared/components/table/pagination.component";
import { APIQueryBuilder } from "src/shared/helpers";
import { useAppDispatch, useAppSelector } from "@core/store/hooks";
import {
  TableSkeleton,
  TableSkeletonInterface,
} from "src/shared/components/table";
import { PaymentsService } from "@core/services/payment.service";
import { pushNotification } from "@core/store/app/app-slice";
import { NotificationPromptTypes } from "src/shared/types/notification.type";
import { PageHeading } from "src/shared/components/page-heading.component";
import { parsePagination } from "src/shared/helpers/list-utils";
import { AdvanceModel, AdvanceStatus } from "@core/models/advance.model";
import { AdvanceSelector } from "src/admin/components/advance-selector.component";
import { formatAmount } from "src/shared/helpers/currency-utils";
import { selectCurrentUser } from "@core/store/app/app-selectors";

export const AdvancesPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [payments, setPayments] = useState<PaymentModel[]>([]);
  const [advances, setAdvances] = useState<AdvanceModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [selectedAdvance, setSelectedAdvance] = useState<AdvanceModel | null>(
    null
  );
  const [pagination, setPagination] = useState<Pagination>();
  const builder = useMemo(() => new APIQueryBuilder(), []);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);

  const tableSkeletonParams: TableSkeletonInterface = {
    cols: 5,
    rows: 20,
  };

  useEffect(() => {
    document.title = `red360 | Advances`;
    const advances = user?.businesses[0].advances || [];
    handleAdvances(advances);
  }, [user]);

  useEffect(() => {
    fetchPayments();
  }, [selectedAdvance, currentPage]);

  const handleAdvances = async (advances: AdvanceModel[]) => {
    try {
      setAdvances(advances);

      if (advances.length > 0) {
        const selectedAdvance =
          advances.find(
            (advance) => advance.status === AdvanceStatus.FUNDED_IN_REPAYMENT
          ) || advances[0];
        setSelectedAdvance(selectedAdvance);
      }
    } catch (e: any) {
      dispatch(
        pushNotification({
          type: NotificationPromptTypes.ERROR,
          message: "Failed to fetch data. Please, contact support.",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPayments = useCallback(async () => {
    if (!selectedAdvance) return;

    setLoading(true);
    try {
      const response = await PaymentsService.getPayments(
        selectedAdvance.id,
        currentPage
      );
      setPagination(parsePagination(response));
      setPayments(response.data);
    } catch (e: any) {
      dispatch(
        pushNotification({
          type: NotificationPromptTypes.ERROR,
          message: "Failed to fetch data. Please, contact support.",
        })
      );
    } finally {
      setLoading(false);
    }
  }, [selectedAdvance, currentPage]);

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      // builder.setPage(page);
      // fetchPayments();
    },
    [builder]
  );

  // const getPaymentAdditionalInfo = (payment: PaymentModel) => {
  //   const paymentSpec = payment.attributes.payment_type[0];

  //   switch (paymentSpec.__component) {
  //     case "payments.split-funding":
  //       return `MID: ${paymentSpec.mid}`;
  //     case "payments.participation-payment":
  //     case "payments.manual-payment":
  //       return paymentSpec.notes;
  //     case "payments.ach-payment":
  //       return `Status: ${paymentSpec.status}; Message: ${paymentSpec.status_message}`;
  //   }
  // };

  // const PaymentsActions = () => {
  //   return (
  //     <section className="flex space-x-5 items-center">
  //       <Menu as="div" className="relative flex items-center text-left">
  //         <button
  //           type="button"
  //           className="text-gray-400 mr-3 hover:text-gray-500 lg:hidden"
  //         >
  //           <span className="sr-only">Filters</span>
  //           <FunnelIcon className="h-5 w-5" aria-hidden="true" />
  //         </button>
  //         <Menu.Button className="p-1 flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
  //           <span className="sr-only">Open options</span>
  //           <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
  //         </Menu.Button>
  //       </Menu>
  //     </section>
  //   );
  // };

  const Filters = () => {
    return (
      <div className="p-6">
        <AdvanceSelector
          advances={advances}
          selectedAdvance={selectedAdvance}
          onAdvanceSelect={setSelectedAdvance}
        />
      </div>
    );
  };

  const Content = () =>
    loading || !selectedAdvance ? (
      <>
        <TableSkeleton
          cols={tableSkeletonParams.cols}
          rows={tableSkeletonParams.rows}
        />
      </>
    ) : (
      <div>
        <div className="pl-6 pb-6">
          <h1 className="text-lg font-semibold leading-7 text-gray-900 ">
            Details
          </h1>
          <span>Payback: {formatAmount(selectedAdvance.total_payback)}</span> •{" "}
          <span>
            Paid to Date:{" "}
            {formatAmount(
              selectedAdvance.total_payback - selectedAdvance.current_balance
            )}
          </span>{" "}
          •{" "}
          <span>Balance: {formatAmount(selectedAdvance.current_balance)}</span>
        </div>

        <div className="flow-root ring-1 ring-black ring-opacity-5">
          <table className="min-w-full divide-y divide-gray-300 common-page-table">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-3 pl-6 py-4 text-left text-sm font-semibold text-gray-900"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Reference
                </th>
              </tr>
            </thead>
            <tbody className="overflow-auto divide-y divide-gray-200 bg-white">
              {payments.map((payment) => (
                <tr key={payment.id} className="px-6">
                  <td className="whitespace-nowrap pl-6 px-3 py-4 text-sm text-gray-500">
                    {payment.attributes.date}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {formatAmount(payment.attributes.amount)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {payment.attributes.reference}
                    {/* {getPaymentAdditionalInfo(payment)} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );

  return (
    <>
      <PageHeading
        title="Advances"
        // controls={<PaymentsActions />}
      />
      <Filters />
      <Content />
      <PaginationComponent
        pagination={pagination as Pagination}
        onPageSet={onPageChange}
      />
    </>
  );
};
