import { FormField } from 'src/shared/components/form';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import cn from 'classnames';
import { UserDto } from '@core/dto/user.dto';
import { UsersService } from '@core/services/users.service';

export const REGEX_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const ProfilePage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {control, register, handleSubmit, formState: { isValid }} = useForm<UserDto>({
    defaultValues: {
      username: "",
      email: "",
    },
  });

  const submit = async (data: UserDto) => {
    setIsSubmitting(true);
    try {
      await UsersService.updateMe(data);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <section className="px-6 mt-10">
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-6 grid-cols-1">
          <FormField
            customStyleClasses="col-span-full"
            required={true}
            control={control}
            fieldPath="username"
            label="User name"
            placeholder="Name"
            registerReturn={register("username", {
              required: "User name is required",
              maxLength: {
                value: 50,
                message: "User name must not exceed 50 characters",
              },
              minLength: {
                value: 2,
                message: "User name must be at least 2 characters",
              },
            })}
          />
        </div>
        <div className="sm:col-span-6">
          <FormField
            customStyleClasses="col-span-full m-w-20"
            required={true}
            control={control}
            fieldPath="email"
            label="Email"
            placeholder="Email"
            registerReturn={register("email", {
              required: "Email is required",
              pattern: {
                value: REGEX_EMAIL,
                message: "Invalid email address",
              },
            })}
          />
        </div>

        <div className="sm:col-span-6 flex items-center justify-end gap-x-6">
          <button
            disabled={isSubmitting}
            type="submit"
            onClick={handleSubmit(submit)}
            className={cn(
              isValid
                ? "bg-orange-700 hover:bg-brand-500"
                : "bg-orange-500 cursor-not-allowed opacity-40",
              "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
            )}
          >
            Update
          </button>
        </div>
      </div>

    </section>
  );
};
