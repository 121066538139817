import React, { type ReactNode } from "react";
import { type Control, type FieldPath, useFormState } from "react-hook-form";
import { get } from "lodash";
import cn from "classnames";

interface Props {
  control: Control<any, any>;
  fieldPath: string;
  customStyleClasses?: string;
  children: ReactNode | ReactNode[];
}
export const FormFieldWrapper: React.FC<Props> = ({ customStyleClasses, children, control, fieldPath }) => {
  const { touchedFields, errors } = useFormState<any>({ control, name: fieldPath as FieldPath<any> });
  const isTouched = get(touchedFields, fieldPath);
  const hasError = !!get(errors, fieldPath);

  return (
      <div
          className={
          cn(
            "p-[1px] relative block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600",
            customStyleClasses,
            hasError && isTouched && "ring-red-500 placeholder:text-red-400 focus:ring-2 focus:ring-inset focus:ring-red-500",
          )
          }
      >
          {children}
      </div>
  );
};
