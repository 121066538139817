import { useEffect, useRef } from "react";

import { useAppSelector } from "@core/store/hooks";
import { Toast } from "primereact/toast";

import "primereact/resources/themes/saga-blue/theme.css";

export const ToastNotifications = () => {
  const alerts = useAppSelector((state) => state.app.toastAlerts);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (alerts.length > 0) {
      toast.current?.show({
        severity: alerts[0].type,
        // summary: alerts[0].type,
        detail: alerts[0].message,
        life: 3000,
      });
    }
  }, [alerts]);

  return <Toast ref={toast} />;
};
