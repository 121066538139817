import { ApiClient } from "src/core/api/api-client";
import { UserModel } from "@core/models/user.model";
import { UserDto } from "@core/dto/user.dto";

export const UsersService = new (class {
  public baseEndpoint = `/users`;

  public async getMe(): Promise<UserModel> {
    const response = await ApiClient.get(
      `${this.baseEndpoint}/me?populate=role,businesses.advances`
    );
    return response.data;
  }

  public async updateMe(payload: UserDto): Promise<UserModel> {
    const response = await ApiClient.put(
      `/users-permissions/users/me`,
      payload
    );
    return response.data;
  }
})();
